<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('contact') }}</h1>

    <!-- EMAIL -->
    <v-container>
      <h2>E-Mail</h2>
      <v-container>
        <v-row>
          <v-col cols="3">Mail</v-col>
          <v-col>
            <a 
              class="contact__link"
              @click="sendMail"
            >
              {{ mail.address }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <!-- WEBSITES -->
    <v-container v-for="(items, category) in contactData" :key="category">
      <h2>{{ category }}</h2>
      <v-container>
        <v-row v-for="(url, website) in items" :key="website">
          <v-col cols="3">{{ website }}</v-col>
          <v-col>
            <a 
              :href="url"
              target="_blank"
              class="contact__link"
            >
              {{ url }}
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <!-- IMPRESSUM -->
    <v-container>
      <h2>Impressum</h2>
      <v-container>
        <v-btn
          class="hover-glow basic-button"
          @click="goTo('impressum')"
        >
          {{ $t('impressum') }}
        </v-btn>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import accountLinkData from '@/assets/data/account-link-data.json';

export default {
  name: 'Contact',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Kontaktinformationen des Musikprojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'},
    ]
  },
  data: () => {
    return {
      mail:
        {
          'address': 'info@synthetic-sympathy.de',
          'subject': 'Subject Test',
          'message': 'Body Test', 
        },
      contactData:
        {
          'Social Media': 
            {
              'Facebook': accountLinkData[0].facebook,
              'Instagram': accountLinkData[0].instagram,
            },
          'Music':
            {
              'Apple Music': accountLinkData[0].apple,
              'Bandcamp': accountLinkData[0].bandcamp,
              'Deezer': accountLinkData[0].deezer,
              'Soundcloud': accountLinkData[0].soundcloud,
              'Spotify': accountLinkData[0].spotify, 
              'Tidal': accountLinkData[0].tidal,
              'Youtube': accountLinkData[0].youtube,
            }
        }
    }
  },
  methods: {
    /**
     * Navigate to different page
     * @param {String} target name of the target page
     */
    goTo(target) {
      this.$router.push({ name: target });
    },    
    /**
     * Opens the users default mail client and prepopulates fields
     */
    sendMail() {
      window.location.href = `mailto:${this.mail.address}?subject=${this.mail.subject}&body=${this.mail.message}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style-variables.scss";

.contact {
  &__link {
    color: $highlight-color;
  }
}

</style>